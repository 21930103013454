import React, { FC } from 'react';
import { DefaultTemplate } from 'templates';
import { Cell, Grid, SectionContainer } from 'components/Grid';
import { Heading } from 'components/Layout';
import { ContactButton } from 'components/Buttons';
import { Link } from 'components/helpers';

import styles from 'scss/landing-page.module.scss';
import gridStyles from 'scss/grid.module.scss';

const LandingPage: FC = () => {
  return (
    <DefaultTemplate
      pageTitle='System Design - Software Engineering Interview Preparation'
      metaDescription='Our system design training program will prepare you for engineering interviews. Two 1.5 hour sessions with personalized exercises, mock interviews, and expert guidance. Gain the confidence to ace the system design portion of your next interview.'
    >
      <div className={styles.pageMain}>
        <SectionContainer name='system-design-interview'>
          <Grid margin={'-15px'} columns={12}>
            <Cell padding={15} width={{ xs: 12 }}>
              <p>
                <Link to='/interview-preparation'>← Back to overview</Link>
              </p>
              <Heading tagLevel={1} classes={gridStyles.marginTop0}>
                <strong>System Design Interview</strong>
              </Heading>
              <Heading tagLevel={2} classes={gridStyles.marginTop0}>
                Overview
              </Heading>
              <p>
                We will conduct two 1.5 hour sessions aimed at preparing you for
                the system design portion of software engineering interviews.
              </p>
              <Heading tagLevel={2} classes={gridStyles.marginTop0}>
                Session 1 - 1.5 hours
              </Heading>
              <p>
                We will start with a diagnostic mock interview to assess your
                current understanding and identify areas that require
                improvement. We will then provide you with tailored exercises to
                enhance your skills. After the first session, you will receive
                detailed notes summarizing our discussion and outlining the
                exercises to complete before the next session.
              </p>
              <Heading tagLevel={2} classes={gridStyles.marginTop0}>
                Session 2 - 1.5 hours
              </Heading>
              <p>
                We will go over your completed exercises and conduct another
                mock interview to refine your abilities with on-the-spot
                coaching.
              </p>
              <Heading tagLevel={2} classes={gridStyles.marginTop0}>
                Results to expect
              </Heading>
              <p>
                This training will equip you with the knowledge, confidence, and
                poise required to ace the system design portion of an
                engineering interview. You will learn to stand out from other
                candidates and excel in your next interview.
              </p>
              <div className={gridStyles.textCenter}>
                <ContactButton>Email to schedule</ContactButton>
              </div>
            </Cell>
          </Grid>
        </SectionContainer>
      </div>
    </DefaultTemplate>
  );
};

export default LandingPage;
